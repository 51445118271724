<template>
  <div class="">
    <div>
      <MasterHeader />
      <!-- <sign-up-modal v-if="hasNewSignupFlow"/> -->
      <div v-if="!isLoaded" class="loader_container">
        <div class="pj-loading"><i class="fas fa-circle-notch fa-spin"></i></div>
      </div>
      <div class="container container-wide" v-if="isLoaded && !isFuture">
        <div class="row">
          <div class="widget-splash splash-line"></div>
          <div class="col-12">
            <div class="widget">
              <div class="widget-splash splash-line"></div>
              <div class="widget-header">
                <h4 class="event_title">
                  <router-link to="/events">Events list</router-link>
                  {{ currentEvent.event_name }}
                </h4>
              </div>
              <div class="widget-body widget-suggestion p_desktop">
                <h2 class="event_header" v-if="isPastEvent">
                  {{ currentEvent.expired_event_title ? currentEvent.expired_event_title : `${currentEvent.event_name} is over` }}
                </h2>
                <h2 v-else class="event_header">Event matching</h2>
                <p class="mt-5 text-center event-text" v-if="isPastEvent">
                  {{ currentEvent.expired_event_description ? currentEvent.expired_event_description :
        "Excelerate Event Matching is no longer active for this event. Do you want to connect with companies at career events via our Event Matching feature? Browse future career events below and find companies you want to connect with."
                  }}
                </p>
                <ul v-if="!hasEventPassed">
                  <li v-for="(attribute, index) in currentEvent.attributes" :key="index">
                    <span class="list-item-counter">{{ index + 1 }}</span>{{ attribute.title }}
                  </li>
                </ul>
                <p class="pb-3" v-if="!hasEventPassed">
                  {{ currentEvent.event_text }}
                </p>
                <template v-if="currentEvent.partner_id == 6">
                  <p class="font-weight-bold">Filter</p>
                  <multiselect multiple v-model="selectedInterests" :options="interests" :showLabels="false"
                    :searchable="false" :allow-empty="true"
                    :placeholder="isZealandFlow ? 'Vælg interesseområde ' :'Select research interest'"
                    track-by="category_name" label="category_name">
                    <template slot="singleLabel" slot-scope="{ option }">{{
        option
      }}</template>
                  </multiselect>
                  <div class="d-flex justify-content-end">

                    <template v-if="isZealandFlow">
                      <button class="btn btn-sm btn-link clear_filters_button" @click="clearInterestFilters">
                        ryd filter
                      </button>
                    </template>

                    <template v-else>
                      <button class="btn btn-sm btn-link clear_filters_button" @click="clearInterestFilters">
                        clear filters
                      </button>
                    </template>
                  </div>
                </template>
                <h5 class="text-right">
                  {{ selectedCompanies }}/{{ totalCompanies }}

                  <template v-if="isZealandFlow">
                    Valgt
                  </template>

                  <template v-else>
                    Selected
                  </template>
                </h5>

                <template v-if="updatingData">
                  <div class="loader_container">
                    <div class="pj-loading"><i class="fas fa-circle-notch fa-spin"></i></div>
                  </div>
                </template>

                <template v-else>
                  <div v-if="companies && companies.length" class="d-flex align-items-center image-company">
                    <img @click="handleCompanyModal(company)" v-for="company in companies" :key="company.company_id"
                      :src="company.company_event_logo
        ? company.company_event_logo
        : require('@/assets/Noimage.png')
        " :alt="company.company_name" class="event_company_image" :class="allInterestedCompanies &&
        allInterestedCompanies.length > 0 &&
        !!allInterestedCompanies.find(
          (item) => +item.company_id === +company.company_id
        )
        ? 'active_image'
        : 'd-block'
        " srcset="" />
                  </div>
                  <div class="" v-else>
                    <template v-if="isZealandFlow">
                      Ingen virksomheder matchede dine kriterier
                    </template>

                    <template v-else>
                      No companies found
                    </template>
                  </div>
                </template>

                <div v-if="isPastEvent" class="d-flex align-items-center justify-content-center my-3">
                  <router-link to="/events"
                    class="btn button-pink">{{ currentEvent.expired_event_button ? currentEvent.expired_event_button : 'Browse future career events' }}</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container container-wide" v-if="isLoaded && isFuture">
        <div class="row">
          <div class="widget-splash splash-line"></div>
          <div class="col-12">
            <div class="widget">
              <div class="widget-splash splash-line"></div>
              <div class="widget-header">
                <h4 class="event_title">
                  <router-link to="/events">Events list</router-link> >
                  {{ currentEvent.event_title }}
                </h4>
              </div>
              <div class="widget-body widget-suggestion p_desktop">
                <h2>{{ currentEvent.promotion_headline }}</h2>
                <p>{{ currentEvent.promotion_text_primary }}</p>
                <br />

                <template><strong class="mb-4">Event start: </strong>
                  <span>{{ currentEvent.start_event_date }}</span></template>
                <!-- <h2 class="event_header">{{hasEventPassed ? 'Event is over' : 'Event matching' }}</h2> -->
                <!-- <p class="mt-5 text-center">{{hasEventPassed ? 'Alex comes up with some smart stuff' : ''}}</p> -->
                <p>
                  <strong>{{ currentEvent.list_headline }}</strong>
                </p>
                <ul class="mt-3" v-if="currentEvent.promotion_listitems">
                  <li v-for="(
                      attribute, index
                    ) in currentEvent.promotion_listitems" :key="index">
                    <span class="list-item-counter">{{ index + 1 }}</span>{{ attribute.title }}
                  </li>
                </ul>
                <p>{{ currentEvent.promotion_text_secondary }}</p>

                <a :href="currentEvent.button_link" class="btn button-pink">{{
        currentEvent.button_text
      }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isLoaded" class="d-flex flex-column align-items-center justify-content-between">
      <h3 class="text-center">3x dine matchingmuligheder med virksomheder</h3>
      <router-link to="/user/student-rising-star" class="btn pink-button w-auto">
        Færdiggør din Excelerate profil
      </router-link>
    </div>
    <div v-if="showCompanyModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div @click="
        (showCompanyModal = false), (currentInterestedCompany = {})
        " class="close-modal">
                  <i class="fa fa-times"></i>
                </div>
                <div class="modal-body text-center">
                  <h4 class="black font-weight-normal">
                    <template v-if="isZealandFlow">
                      Jeg er interesseret i
                    </template>

                    <template v-else>
                      I am interested in
                    </template>
                  </h4>
                  <h3 class="black py-3">{{ selectedCompany.company_name }}</h3>
                  <h6 class="text-left font-weight-bold my-3">

                    <template v-if="isZealandFlow">
                      Matchinginteresser
                    </template>

                    <template v-else>
                      Connection purpose
                    </template>
                  </h6>
                  <ul class="text-left font-weight-bold black">
                    <li v-for="interest in currentConnectionPurposes" :key="interest.id" class="flex-center">
                      <label class="switch">
                        <input type="checkbox" v-model="currentInterestedCompany.company_attributes[
        +interest.id
      ]
        " />
                        <span class="slider round"></span></label>
                      {{ interest.name }}
                    </li>
                  </ul>
                  <div class="d-block">
                    <span v-if="isProcessing" class="small_loader"></span>
                  </div>
                  <div class="alert alert-danger mt-3" v-if="hasError">
                    Please select at least one of the interests.
                  </div>
                  <div v-if="!currentInterestedCompany.id">
                    <a target="_blank" class="pink-button-consult mr-3" :href="companyUrl(selectedCompany)">

                      <template v-if="isZealandFlow">
                        Læs mere
                      </template>

                      <template v-else>
                        Read more
                      </template>
                    </a>
                    <button @click="handleAdd" class="btn pink-button">

                      <template v-if="isZealandFlow">
                        Tilføj
                      </template>

                      <template v-else>
                        Add
                      </template>
                    </button>
                  </div>

                  <div class="" v-else>
                    <button @click="handleAdd" class="btn pink-button">

                      <template v-if="isZealandFlow">
                        Opdater
                      </template>

                      <template v-else>
                        Update
                      </template>
                    </button>
                    <button @click="deleteCompany(currentInterestedCompany.id)" class="btn">

                      <template v-if="isZealandFlow">
                        Fjern
                      </template>

                      <template v-else>
                        Remove
                      </template>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="showRolesModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div @click="
        (showRolesModal = false), (currentInterestedCompany = {})
        " class="close-modal">
                  <i class="fa fa-times"></i>
                </div>
                <div class="modal-body text-center">
                  <p class="black font-weight-normal">

                    <template v-if="isZealandFlow">
                      Vil du gerne ansøge?
                    </template>

                    <template v-else>
                      Would you like to apply?
                    </template>
                  </p>
                  <h3 class="black py-2">{{ selectedCompany.company_name }}</h3>
                  <div class="text-left mb-4" v-for="interest in companyJobs" :key="interest.id">
                    <p class="role-sub">
                      {{ getConnectionName(interest.jobtype) }}<span
                        class="center-dot"></span>{{ interest.department_name }}
                    </p>
                    <strong class="wrapped-text my-3">{{
        interest.profile_title
      }}</strong>
                    <div class="d-flex align-self-start mt-3">
                      <a target="_blank" class="pink-button-consult" :href="getJobUrl(interest)">

                        <template v-if="isZealandFlow">
                          Læs mere
                        </template>

                        <template v-else>
                          Read more
                        </template>
                      </a>
                      <label class="switch ml-5">
                        <input type="checkbox" v-model="interest.selected
        " />
                        <span class="slider round"></span></label>
                    </div>
                    <hr />
                  </div>
                  <div class="d-block">
                    <span v-if="isProcessing" class="small_loader"></span>
                  </div>
                  <button class="btn btn-sm btn-link" @click="handleJobBack">

                    <template v-if="isZealandFlow">
                      Tilbage
                    </template>

                    <template v-else>
                      Back
                    </template>
                  </button>
                  <button @click="handleJobs" class="btn pink-button">

                    <template v-if="isZealandFlow">
                      Ansøg
                    </template>

                    <template v-else>
                      Submit
                    </template>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="showGreatModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div @click="
        (showGreatModal = false), (currentInterestedCompany = {})
        " class="close-modal">
                  <i class="fa fa-times"></i>
                </div>
                <div class="modal-body text-center">
                  <h3 class="black py-2">

                    <template v-if="isZealandFlow">
                      High five!
                    </template>

                    <template v-else>
                      Great
                    </template>
                  </h3>
                  <p>

                    <template v-if="isZealandFlow">
                      Dine matchinginteresser er registreret hos virksomheden.
                    </template>

                    <template v-else>
                      Your selection and application has been registered!
                    </template>
                  </p>
                  <strong>

                    <template v-if="isZealandFlow">
                      Vil du forøge din matchingmulighed?
                    </template>

                    <template v-else>
                      Do you want to increase your chances?
                    </template>
                  </strong>
                  <p>

                    <template v-if="isZealandFlow">
                      Færdiggør din Excelerate profil og skil dig ud foran virksomheden.
                    </template>

                    <template v-else>
                      Complete your profile after the event
                    </template>
                  </p>
                  <button @click="showGreatModal = false" class="pink-button-consult mr-3">
                    Færdiggør profil
                  </button>
                  <button v-if="isZealandFlow" @click="showGreatModal = false" class="btn mobile_margin pink-button w-auto">
                      Fortsæt udforskning
                  </button>
                  <button v-else @click="showGreatModal = false" class="btn pink-button w-auto">
                      OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import MasterHeader from "@/components/MasterHeader.vue";
import { mapState } from "vuex";
import apiInstance from "../../axios/axios";
import SignUpModal from "../../components/signUpModal.vue";
import { base64ToString } from "../../utils/utils";
import Multiselect from "vue-multiselect";

import moment from "moment";
import generalService from "../../services/generalService";
let userId = localStorage.getItem('uid')
userId = base64ToString(userId)

export default {
  components: {
    MasterHeader,
    Multiselect,
  },
  data() {
    return {
      isLoaded: false,
      isPastEvent: false,
      isFuture: false,
      selectedCompany: null,
      isProcessing: false,
      allInterestedCompanies: null,
      showCompanyModal: false,
      showRolesModal: false,
      showGreatModal: false,
      hasError: false,
      selectedCompanies: 0,
      currentInterestedCompany: {},
      currentConnectionPurposes: {},
      companyInterestsArray: [],
      interests: [],
      selectedInterests: [],
      updatingData: false,
      companies: [],
      companyJobs: [],
    };
  },
  mounted() {
    if (!this.$store.getters.studentData) {
      this.$store.dispatch('getStudentData', userId);
    }
    Promise.all([
      this.$store
        .dispatch("getEvents")
        .then(() => this.getStudentCompanyInterests()),
      generalService.getAllResearchInterests().then((res) => {
        const interestList = res.sort((a, b) => a.category_name.localeCompare(b.category_name))
        this.interests = interestList
        // this.setSelectedInterests();
      }),
      // this.$store.dispatch("getUniversities").then(() => {
      //   //! looks awful but cannot take a risk with the data
      //   const uniId = this.$store.getters.universities.universities.find(
      //     (uni) => uni.university_name.toLowerCase().includes("zealand")
      //   ).id;
      //   generalService.getUniversityData(uniId).then(({ programmes }) => {
      //     const programmesList = programmes.sort((a, b) => a.programme_name.localeCompare(b.programme_name));
      //     this.programmes = programmesList;
      //   });
      // }),
      generalService
        .getConnectionPurposes()
        .then((res) => (this.companyInterestsArray = res)),
    ]).then(() => (this.isLoaded = true));
  },
  created() {
    // const userType = localStorage.getItem('i_am')
    // const userVerified = localStorage.getItem('u-verified')
    let userType = localStorage.getItem("i_am");
    userType = base64ToString(userType);
    let userVerified = localStorage.getItem("u-verified");
    ``;
    if (userVerified == 0 && userType == "student") {
      this.$router.push("/user/student-welcome");
    }
    if (userVerified == 0 && userType == "professional") {
      this.$router.push("/user/welcome");
    }
  },
  watch: {
    // ! don't like it but had to make it work this way
    selectedInterests: function (newValue, oldValue) {
      if (newValue !== oldValue) {
        const interestIds = this.selectedInterests.reduce((acc, val) => {
          acc.push(val.id);
          return acc;
        }, []);
        if (this.currentEvent) {
          this.updatingData = true;
          generalService
            .getCompaniesByInterests({
              // university_id: 12,
              interestIds,
              event_id: this.currentEvent.event_id,
            })
            .then((res) => {
              this.companies = res;
              this.updatingData = false;
            });
        }
        // }, 1500);
      }
    },
    companies: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        let sortedCompanies = newVal.sort((a, b) =>
          a.company_name.localeCompare(b.company_name)
        );
        this.companies = sortedCompanies
    }
  },
  },
  computed: {
    ...mapState(["user", "event"]),
    hasNewSignupFlow() {
      const vm = this;
      const isValid = vm.$store.getters.currentProgress >= 0 ? true : false;
      const isStudent = vm.$store.getters.role === "Student";
      if (!isValid && isStudent) {
        localStorage.setItem("notSignedUp", 1);
      }
      if (isValid) {
        localStorage.removeItem("notSignedUp");
      }
      return true;
    },
    isZealandFlow() {
      return this.$store.getters.isZealandFlow
    },
    currentEvent() {
      const vm = this;
      const name = this.$route.params.id;
      let myEvent = null;
      if (this.event.events == undefined || this.event.events == null) return;
      Object.keys(this.event.events).forEach((eventType) => {
        const currentEvent = this.event.events[eventType];
        if (currentEvent && currentEvent.length > 0) {
          currentEvent.forEach((event) => {
            const sluggedEventName = event.event_name.split(" ").join("-");
            if (sluggedEventName == name) {
              if (eventType == "pastevent") vm.isPastEvent = true;
              if (eventType == "futureevent") vm.isFuture = true;
              myEvent = event;
            }
          });
        }
      });
      vm.companies = myEvent.companies;
      return myEvent;
    },
    totalCompanies() {
      return this.currentEvent.companies.length;
    },
    hasEventPassed() {
      return moment(this.currentEvent.end_event_date).isBefore(moment());
    },
  },
  methods: {
    handleJobs() {
      const payload = {
        company_id: +this.selectedCompany.company_id,
        event_id: +this.currentEvent.event_id,
        jobs: this.companyJobs,
      }
      generalService.setInterestedJobs(payload).then(() => {
        this.showRolesModal = false;
        this.showCompanyModal = false;
        this.showGreatModal = true;
      }).catch((err) => console.log(err, 'no plans here yet'))
    },
    getJobUrl(interest) {
      console.log(interest);
      if (!interest.profile_title) return;
      return `${process.env.VUE_APP_WEB_BASE_URL}en/companies/${this.selectedCompany.company_name
        .split(" ")
        .join("-")}/#collapse-job-item-${interest.profile_title
          .split(" ")
          .join("-")}`;
      // return `excelerate.dk/en/companies/${this.selectedCompany.company_name.split(' ').join('-')}`;
      // https://excelerate.dk/en/companies/
      // return
    },
    handleJobBack() {
      this.showRolesModal = false;
      this.showCompanyModal = true;
      this.showGreatModal = false;
    },
    handleCompanyModal(company) {
      console.log(company, 'ss');
      if (company.connection_purpose_ids && company.connection_purpose_ids.length) {
        this.currentConnectionPurposes = this.companyInterestsArray.filter(
          ({ id }) => company.connection_purpose_ids.includes(id)
        );
      } else {
        this.currentConnectionPurposes = this.companyInterestsArray;
      }
      if (this.hasEventPassed) return;
      let currentCompanyData;
      this.currentInterestedCompany = {};
      if (
        this.allInterestedCompanies &&
        this.allInterestedCompanies.length > 0 &&
        this.allInterestedCompanies.find(
          (item) => +item.company_id === +company.company_id
        )
      ) {
        currentCompanyData = this.allInterestedCompanies.find(
          (item) => +item.company_id === +company.company_id
        );
        this.currentInterestedCompany = currentCompanyData;
        if (typeof currentCompanyData.company_attributes === "string") {
          this.currentInterestedCompany.company_attributes = JSON.parse(
            currentCompanyData.company_attributes
          );
        }
        // console.log(JSON.parse(
        //   currentCompanyData.company_attributes
        // ))
        this.currentInterestedCompany.created_at = null;
        this.currentInterestedCompany.updated_at = null;
      } else {
        const companyInterests = this.companyInterestsArray.filter((interest) =>
          company.connection_purpose_ids.includes(interest.id)
        );
        let companyInterestIds;
        if (companyInterests.length) {
          companyInterestIds = companyInterests.reduce((acc, { id }) => {
            acc[id] = false;
            return acc;
          }, {});
        } else {
          companyInterestIds = this.companyInterestsArray.reduce(
            (acc, { id }) => {
              acc[id] = false;
              return acc;
            },
            {}
          );
        }
        this.currentInterestedCompany = {
          user_id: base64ToString(localStorage.getItem("uid")),
          id: null,
          event_id: this.currentEvent.event_id,
          company_id: company.company_id,
          company_attributes: { ...companyInterestIds },
        };
      }
      this.selectedCompany = company;
      this.showCompanyModal = true;
    },
    async getStudentCompanyInterests() {
      try {
        const res = await apiInstance.get(
          `getEvent/${this.currentEvent.event_id}/${base64ToString(
            localStorage.getItem("uid")
          )}`
        );
        this.allInterestedCompanies = res.data.data.payload;
        this.selectedCompanies = this.allInterestedCompanies.length;
      } catch (error) {
        //
        console.log(error);
      }
    },
    async handleAdd() {
      try {
        this.hasError = false;
        if (
          Object.values(
            this.currentInterestedCompany.company_attributes
          ).includes(true)
        ) {
          this.isProcessing = true;
          await apiInstance
            .post("addStudentEvent", this.currentInterestedCompany)
            .then(
              ({
                data: {
                  data: { payload },
                },
              }) => {
                if (payload.length) {
                  this.showRolesModal = true;
                  this.showCompanyModal = false;
                  this.companyJobs = payload;
                } else {
                  this.showRolesModal = false;
                  this.showGreatModal = true;
                  this.showCompanyModal = false;
                }
                this.isProcessing = false;
                // this.showCompanyModal = false;
                this.getStudentCompanyInterests();
              }
            );
        } else {
          this.hasError = true;
          return;
        }
      } catch (error) {
        //
      }
    },
    async deleteCompany(id) {
      try {
        this.isProcessing = true;
        await apiInstance.delete(`deleteStudentEvent/${id}`).then(() => {
          this.showCompanyModal = false;
          this.isProcessing = false;
          this.getStudentCompanyInterests();
        });
      } catch (error) {
        //
      }
    },
    getConnectionName(id) {
      const result = this.companyInterestsArray.find((item) => {
        return +item.id === +id;
      });
      return result ? result.name : null;
    },
    setSelectedInterests() {
      if (this.user && this.user.student && this.user.student.researchInterests.length>0) {
        this.selectedInterests = this.interests.filter(interest => this.user.student.researchInterests.includes(interest.id));
      }
    },
    clearInterestFilters() {
      this.selectedInterests = []
    },
    companyUrl() {
      console.log(this.selectedCompany);
      return `${process.env.VUE_APP_WEB_BASE_URL}en/companies/${this.selectedCompany.company_name
        .split(" ")
        .join("-")}`
    }
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.thesis-body {
  padding: 2rem;
  background: #fff;
  -webkit-box-shadow: 0 1px 4px rgb(0 0 0 / 15%);
  box-shadow: 0 1px 4px rgb(0 0 0 / 15%);
  border-radius: 4px;
  margin: 0;
}

.text-bold {
  font-weight: 700;
}

.thesis-button {
  list-style: none;
  border: 1px solid #da518a;
  padding: 0.2rem 0.5rem;
  border-radius: 3px;
  margin-right: 0.4rem;
  margin-bottom: 0.4rem;
  color: #da518a;
  font-size: 14px;
  background: transparent;
}

table tr th:not(:last-child),
td:not(:last-child) {
  border-right: 1px solid #dee2e6;
}

table tr th:not(:first-child) {
  background-color: #fcfcfc;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(219, 95, 138, 0.2);
  border-right: 1.1em solid rgba(219, 95, 138, 0.2);
  border-bottom: 1.1em solid rgba(219, 95, 138, 0.2);
  border-left: 1.1em solid #db5f8a;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

li {
  list-style: none;
}

ul {
  padding: 0;
}

ul>li {
  display: flex;
  align-items: center;
  column-gap: 16px;
  font-size: 16px;
}

.list-item-counter {
  margin-right: 1rem;
  color: white;
  margin-bottom: 5px;
  background-color: #bc1e73;
  border-radius: 50%;
  display: inline-block;
  width: 30px;
  height: 30px;
  font-weight: bold;
  text-align: center;
  line-height: 32px;
}

.image-company {
  width: 100%;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
}

.event_company_image {
  width: 18%;
  /* height: auto; */
  object-fit: cover;
  height: 120px;

  cursor: pointer;
}

@media (max-width: 767px) {

  ul>li {
    display: block;
    margin-top: 14px;
  }
  .flex-center {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 10px;
    /* Adjust the gap as needed */
  }
  .image-company {
    width: 100%;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
  }

  .event_company_image {
    width: 45%;
    height: auto;
    cursor: pointer;
  }
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-content {
  max-width: 500px;
  padding: 5%;
  border-radius: 10px;
  position: relative;
}

.close-modal {
  position: absolute;
  right: -10px;
  top: -10px;
  background: #fff;
  /* box-shadow: 0px 5px 5px #ccc; */
  padding: 10px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 15px;
  cursor: pointer;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #3aa948;
}

input:focus+.slider {
  box-shadow: 0 0 1px #3aa948;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.pink-button {
  background-color: #bc1e73;
  color: #fff;
  width: 100px;
}

.small_loader {
  width: 20px;
  height: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 5px solid #bc1e73;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.active_image {
  border: 6px solid #bc1e73;
  border-radius: 5px;
}

.event_title {
  /* text-overflow: ellipsis; */
  height: auto !important;
}

.event_header {
  margin: 20px 0;
}

@media (min-width: 799px) {
  .event_header {
    text-align: center;
  }
}

.event-text {
  max-width: 800px;
  margin: auto;
}

.wrapped-text {
  word-break: break-word;
  word-wrap: break-word;
  font-weight: bold;
  font-size: 22px;
  color: #000;
}

.pink-button-consult {
  color: #bc1e73;
  padding: 2%;
  border: 2px solid #bc1e73;
  font-weight: 600;
  border-radius: 5px;
  text-decoration: none;
}

.role-sub {
  font-size: 15px !important;
  color: #5a2871;
  font-weight: 600;
  margin-bottom: 0px !important;
}

.center-dot {
  width: 6px;
  height: 6px;
  background-color: #5a2871;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  margin: 0 2px 0 7px;
}

.pink-button-consult {
  margin-top: -4px !important;
}
</style>

<style>
.multiselect__option--highlight {
  background: #bc1e73 !important;
}

.multiselect__tag {
  color: #000;
  background: transparent;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover,
.multiselect__option--selected.multiselect__option--highlight {
  background: #bc1e73 !important;
}

.multiselect__option--selected.multiselect__option--highlight {
  /* background: #5A2871; */
}

.loader_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.clear_filters_button {
  font-size: 12px;
}
.modal-body {
  overflow-y: auto;
  max-height: 90vh;
}
.flex-center {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom:10px;
  /* Adjust the gap as needed */
}
.flex-center label{
  margin-bottom:0;

}
@media(max-width: 499px) {
  .mobile_margin{
    margin-top: 10px 
  }
}
@media(min-width: 799px) {
  .p_desktop{
    padding-left: 3rem!important;
    padding-right: 3rem!important;
  }
}

</style>